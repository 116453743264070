/* eslint-disable no-unused-vars */
import React, { useState, useEffect, version } from "react";
import axios from "axios";
import { ip } from "../../../api";
import '../AddonDetailPage.css'
import DOMPurify from 'dompurify';


const DownloadButton = ({ addon, code_name, t }) => {
    const [lastDownloadTime, setLastDownloadTime] = useState(0)
    const handleSubmit = async (event) => {
        console.log('다운로드 로직')
        event.preventDefault();

        try {
            const currentTime = Date.now();
            if (currentTime - lastDownloadTime < 2000) {
                alert(`${(2000 - currentTime + lastDownloadTime) / 1000}초 후 다시 시도하세요`)
                return;
            }
            if (!document.getElementById('download').term_agree_button.checked) {
                // document.getElementById('exampleModal').modal();
                alert(t('ui_term_agree'))
                return;
            }
            setLastDownloadTime(currentTime);
            const response = await axios.post(`http://${ip}/api/addons/download/`, { code_name: code_name, version: '1.0.0' }, {
                responseType: 'blob',
            });
            console.log(response['headers'])
            const contentDisposition = response.headers['content-disposition'];
            console.log(contentDisposition)
            let filename = addon.name; // Default name
            if (contentDisposition) {
                let filenameMatch = contentDisposition.match(/filename\*?=([^;]+)/i);
                if (filenameMatch) {
                    let filenamePart = filenameMatch[1].trim();

                    if (filenamePart.startsWith("UTF-8''")) {
                        filename = decodeURIComponent(filenamePart.replace("UTF-8''", ''));
                    } else {
                        // Remove potential quotes around filename
                        filename = filenamePart.replace(/(^")|("$)/g, '');
                    }
                }
            }

            // 파일 다운로드
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // 파일 이름 지정
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            const download_count = document.getElementById('download_count')
            download_count.innerHTML = parseInt(download_count.innerHTML) + 1
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <form id="download" onSubmit={handleSubmit}>
            <div id='term_agree'>
                <input type="checkbox" id="term_agree_button" name="term_agree_button" />
                <label htmlFor="term_agree_button" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('AddonDetailPage-DoanloadButton-term_agree_button')) }}></label>
            </div>
            <div id="down_and_sel">
                <button type="submit" id='download_button'>
                    <span class="material-symbols-rounded">
                        download
                    </span>
                    다운로드</button>
                <button type="button" id="versions_button">
                    <span class="material-symbols-rounded">
                        history_2
                    </span>
                    다른 버전</button>
            </div>
            {/* <form className="password_check" onSubmit={false}>
                <input type="password" />
                <input type="submit" />
                <input type="reset" />
            </form> */}

            {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel"><span translate='no' class="material-symbols-outlined">
                                warning
                            </span></h1>
                        </div>
                        <div class="modal-body">
                            {t('ui_term_agree')}
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">{t('ui_confirm')}</button>
                        </div>
                    </div>
                </div>
            </div> */}
        </form >
    )
}

export default DownloadButton;